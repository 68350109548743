<template>
  <div class="add_idcard" v-if="isShow" v-wechat-title="title">
    <!-- 新增上传身份证正反面页面  不做ocr识别 -->
    <div class="top">
      <Steps :dataSource="list" :active="3" :class="short_name === 'xrb' ? 'steps last' : 'steps'"></Steps>
      <!-- <Steps :dataSource="list" :active="3" class="steps"></Steps> -->
      <!-- <div class="remark">请您按照步骤提交申请所需要的信息～</div> -->
    </div>
    <div class="idcard_upload">
      <div class="uploadTitle">复核身份证</div>
      <div class="uploadStandard">每次最多上传0/2张，单张文件不超过5MB</div>
      <div class="ocrBox">
        <div class="pass-upload" v-if="!isWeixin && !if_pass" @click.stop="uploadPass"></div>
        <template v-if="isWeixin">
          <div class="upload-img" @click="chooseImage('front')">
            <div class="upload-img-a" :style="{ backgroundImage: 'url(' + idcard_front + ')' }"></div>
          </div>
          <div class="upload-img" @click="chooseImage('back')">
            <div class="upload-img-b" :style="{ backgroundImage: 'url(' + idcard_back + ')' }"></div>
          </div>
        </template>
        <template v-else>
          <ImageUploader class="upload-img" v-model="img_front" single @change="uploadFront">
            <template slot="content">
              <div class="upload-img-a" :style="{ backgroundImage: 'url(' + idcard_front + ')' }"></div>
            </template>
          </ImageUploader>
          <ImageUploader class="upload-img" v-model="img_back" single @change="uploadBack">
            <template slot="content">
              <div class="upload-img-b" :style="{ backgroundImage: 'url(' + idcard_back + ')' }"></div>
            </template>
          </ImageUploader>
        </template>
      </div>
      <div class="standard">上传标准</div>
      <div class="uploadMark">证件必须是清晰彩色原件电子版本。可以是扫描件或是数码拍摄照片。仅支持jpg，png，jpeg的图片格式。</div>
      <div class="standardImg">
        <img src="/static/uploadExp.png" />
      </div>
      <div class="btn">
        <van-button round type="info" size="large" class="up" :disabled="disabled" @click="next()">下一步</van-button>
      </div>
      <div class="goBack" @click="back">
        <img src="/static/goBack.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { appHost } from '@/utils/host'
import ImageUploader from '@/components/ImageUploader'
import Steps from '@/components/steps.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { DownloadImage, getDoctorForm } from '@/api/api'
import { parseUrl } from '@/utils/utils'
import { Toast, Dialog, ImagePreview } from 'vant'
export default {
  components: {
    Steps,
    ImageUploader
  },
  data() {
    return {
      baseFileUrl: appHost,
      formList: [], //资质表单列表
      formIndex: 3, //当前表单序号
      if_pass: false, //如果为false资质认证成功，就不重新上传证件照片
      project_doctor_id: null,
      idcards: [
        {
          image: '',
          type: 'back' //国徽
        },
        {
          image: '',
          type: 'front' //人像
        }
      ],
      disabled: false,
      idcard_front: '/static/idcard_front.png',
      idcard_back: '/static/idcard_back.png',
      img_front: [], // 非微信环境上传身份证正面
      img_back: [], // 非微信环境上传身份证反面
      isShow: true,
      title: '',
      list: [
        {
          step_name: '身份信息'
        },
        {
          step_name: '职称信息'
        },
        {
          step_name: '上传证件'
        },
        {
          step_name: '上传身份证'
        },
        {
          step_name: '签署协议'
        }
      ],
      short_name: ''
    }
  },
  computed: {
    ...mapGetters(['sub_id']),
    ...mapState(['userId', 'idcardList', 'basicInformation'])
  },
  mounted() {
    // 自动填充身份证正反面
    this.project_doctor_id = this.$route.query.project_doctor_id
    this.short_name = this.basicInformation.projects[0].short_name
    if (['xrb', 'sshy'].includes(this.short_name)) {
      this.list[4].step_name = `阅读知情同意书`
    }
    this.init()
  },
  methods: {
    ...mapActions(['SET_IDCARD_LIST']), //存身份证
    async init() {
      this.title = '上传身份证'
      console.log('this.baseFileUrl ', this.baseFileUrl)
      console.log('idcardList', this.idcardList)

      await getDoctorForm({
        params: {
          doctor_id: this.userId,
          subproject_id: this.sub_id
        }
      })
        .then(res => {
          console.log('上传身份证图接口：', res)
          this.formList = res
          this.if_pass = this.formList[this.formIndex].is_edit_idcard
          console.log('身份证图片', this.formList[this.formIndex].form[0].value)
          let idcard_img = this.formList[this.formIndex].form[0].value
          // 显示身份证
          if (idcard_img.length > 0) {
            this.idcards[0].image = idcard_img[0]
            this.idcards[1].image = idcard_img[1]
            this.idcard_front = idcard_img[1]
            this.idcard_back = idcard_img[0]
            this.SET_IDCARD_LIST(idcard_img)
          }
          // 返回时也显示图片
          if (this.idcardList.length > 0 && idcard_img.length == 0) {
            console.log('返回时也显示图片')
            this.idcards[0].image = this.idcardList[0]
            this.idcards[1].image = this.idcardList[1]
            this.idcard_front = this.idcardList[1]
            this.idcard_back = this.idcardList[0]
            // this.SET_IDCARD_LIST(this.idcardList)
          }
        })
        .catch(err => {})
    },
    getImgByteSize(data) {
      let size = 0
      if (data) {
        // 获取base64图片byte大小
        const equalIndex = data.indexOf('=') // 获取=号下标
        if (equalIndex > 0) {
          const str = data.substring(0, equalIndex) // 去除=号
          const strLength = str.length
          const fileLength = strLength - (strLength / 8) * 2 // 真实的图片byte大小
          size = Math.floor(fileLength) // 向下取整
        } else {
          const strLength = data.length
          const fileLength = strLength - (strLength / 8) * 2
          size = Math.floor(fileLength) // 向下取整
        }
      }
      return size * 1.657
    },
    chooseImage(side) {
      this.if_pass = this.formList[this.formIndex].is_edit_idcard
      console.log('this.if_pass', this.if_pass)
      if (!this.if_pass) {
        //已资质认证成功不用重新上传身份证
        Dialog.alert({
          title: '提示',
          message: '身份证符合要求，无须重复上传',
          confirmButtonColor: '#4B99FF'
        }).then(() => {})
      } else {
        this.$wechat.chooseImage({
          count: 1,
          // sizeType: ["compressed"], // 可以指定是原图还是压缩图，默认二者都有
          sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
          success: async res => {
            try {
              const serverId = res.localIds[0]
              this.$wechat.getLocalImgData({
                localId: res.localIds[0],
                success: async response => {
                  let localData = response.localData
                  this.picList = response
                  let itemSize = await this.getImgByteSize(localData) //获取base64大小
                  console.log(itemSize, 'itemSize')
                  // alert(itemSize)
                  if (itemSize > 20480000) {
                    Toast('图片过大，请重新选择图片')
                  } else {
                    // alert("this.uploadImage(serverId)")
                    await this.uploadImage(serverId, side)
                  }
                }
              })
            } catch (err) {
              let str = JSON.stringify(err.response)
              let json = JSON.parse(str)
              console.log('err1', json)
              Dialog.alert({
                title: '提示',
                message: json.data.data.message,
                confirmButtonColor: '#4B99FF'
              })
            }
          },
          fail: function () {
            Toast('选图失败')
          }
        })
      }
    },
    uploadImage(serverId, side) {
      return new Promise((resolve, reject) => {
        this.$wechat.uploadImage({
          localId: serverId,
          isShowProgressTips: 1,
          success: async res => {
            var serverId = res.serverId // 返回图片的服务器端ID
            Toast.loading({
              duration: 0, // 持续展示 toast
              forbidClick: true
            })
            try {
              let res = await DownloadImage({
                params: {
                  serverId: serverId
                }
              })
              if (side == 'front') {
                this.idcard_front = parseUrl(res, this.baseFileUrl)
                this.idcards[1].image = parseUrl(res, this.baseFileUrl)
              } else if (side == 'back') {
                this.idcard_back = parseUrl(res, this.baseFileUrl)
                this.idcards[0].image = parseUrl(res, this.baseFileUrl)
              }
              Toast.clear()
            } catch (err) {
              Toast.clear()
              reject(err)
            }
          },
          fail: function () {
            Toast('图片上传失败')
          }
        })
      })
    },
    // 下一步
    next() {
      // 如果身份证图片有两张
      if (this.idcards[0].image != '' && this.idcards[1].image != '') {
        let idcard_img = []
        idcard_img.push(this.idcards[0].image)
        idcard_img.push(this.idcards[1].image)
        this.SET_IDCARD_LIST(idcard_img)
        this.$router.replace({
          path: '/add_agreement',
          query: {
            project_doctor_id: this.project_doctor_id,
            dire: 'front'
          }
        })
      } else if (this.formList[this.formIndex].form[0].require == 1) {
        Dialog.alert({
          title: '提示',
          message: '请上传证件照片',
          confirmButtonColor: '#4B99FF'
        })
      }
    },
    // 返回
    back() {
      this.$router.replace({
        path: '/add_idsn',
        query: {
          project_doctor_id: this.project_doctor_id,
          dire: 'back'
        }
      })
    },
    uploadPass() {
      Dialog.alert({
        title: '提示',
        message: '身份证符合要求，无须重复上传',
        confirmButtonColor: '#4B99FF'
      }).then(() => {})
    },
    uploadFront() {
      const len = this.img_front.length
      if (len > 0) {
        this.idcard_front = this.img_front[0]
        this.idcards[1].image = this.img_front[0]
      } else {
        this.idcard_front = '/static/bankcard_front.png'
        this.idcards[1].image = ''
      }
    },
    uploadBack() {
      const len = this.img_back.length
      if (len > 0) {
        this.idcard_back = this.img_back[0]
        this.idcards[0].image = this.img_back[0]
      } else {
        this.idcard_back = '/static/bankcard_front.png'
        this.idcards[0].image = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add_idcard {
  .top {
    width: 100%;
    height: 97px;
    background-image: linear-gradient(to right, #5b9bf0, #3d87ea);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 14px;

    .steps {
      width: 100%;
      margin-bottom: 17px;
    }

    .remark {
      width: 243px;
      height: 21px;
      background: #006ae1;
      border-radius: 10px;
      font-size: 11px;
      font-weight: bold;
      color: #ffffff;
      line-height: 21px;
    }
  }
  .idcard_upload {
    height: 100%;
    width: 100%;
    padding: 9px 11px 0 9px;
    box-sizing: border-box;
    text-align: left;

    .standard,
    .identity,
    .uploadTitle {
      font-size: 15px;
      color: #232323;
      margin-bottom: 13px;
    }

    .uploadStandard,
    .uploadMark {
      // color: #A8A8A8;
      color: #565656;
      font-size: 12px;
      margin-bottom: 3px;
    }

    .ocrBox {
      height: 128px;
      width: 100%;
      display: flex;
      position: relative;

      .upload-img {
        // height: 94px;
        // width: 139px;
        height: 96px;
        width: 165px;
        background-position: left top, right top, right bottom, left bottom;
        background-repeat: no-repeat;
        background-size: 16px;
        padding: 20px;

        .upload-img-a,
        .upload-img-b {
          width: 100%;
          height: 0;
          padding-top: 63%;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          background-color: #fff;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.075);
          border-radius: 5px;
        }
      }
      .pass-upload {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.025);
        z-index: 9999;
      }
    }

    .uploadMark {
      margin-bottom: 26px;
    }

    .standardImg {
      width: 100%;
      height: 90px;
      padding: 0 25px;
      box-sizing: border-box;
      margin-bottom: 20px;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .information {
      margin-bottom: 10px;
    }

    .tips {
      color: #a8a8a8;
      font-size: 12px;
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
      margin-bottom: 70px;
      box-sizing: border-box;
      text-align: center;
    }
  }
}
</style>
